// CartSkeleton.tsx
import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export default function CartSkeleton() {
  return (
    <Box sx={{ width: '100%' ,display: 'flex' }}>
      <Skeleton variant="rectangular" animation="wave" width="60%" height={200} sx={{ marginBottom: 2 , marginRight:2 }} />
      <Skeleton variant="rectangular" animation="wave" width="30%" height={200} />
    </Box>
  );
}
