import React from 'react';
import InformationIcon from '../../assets/img/information.png'; // Ensure this path is correct
import { Modal } from './modal'; // Import your custom Modal

interface UnderMaintenanceProps {
    open?: boolean; // Modal open state
    errorType?: string; // Error type to determine content
    onClose: () => void; // Function to close the modal
}

export function UnderMaintenance({ open, errorType, onClose }: UnderMaintenanceProps) {
    const isUnderMaintenance = errorType === 'UNDER_MAINTENANCE';

    if (!isUnderMaintenance) return null; // Do not render the modal if it's not open

    return (
        <Modal onClose={onClose} className='bg-[#FFF4E4]'>
            <div className="flex justify-start mb-4">
                <div className="w-10 h-10 p-2 mr-[14px] border-2 bg-white self-center border-white rounded-full">
                    <img src={InformationIcon} alt="Wait" className="w-full h-full" />
                </div>
                <h3 className="font-bold text-[#14223D] text-lg tracking-[-0.36px]">System Temporarily<br />Unavailable</h3>
            </div>

            {/* Divider */}
            <div className="border-b border-[#DFDEEE] mb-4" />

            <p className="text-[#768195] tracking-[-0.36px] text-md text-bold leading-relaxed mb-6 max-w-[25rem]">
                Our system is currently <strong className='text-deep-blue'>down for maintenance.</strong> Our team has been duly informed
                about the action you intended. If required, <strong className='text-deep-blue'>you will be notified</strong> by email when there's
                an update for you. Thank you for understanding.
            </p>

            {/* Divider */}
            <div className="border-b border-[#DFDEEE] mb-4" />

            {/* Close or Continue button */}
            <button
                onClick={onClose}
                className="mt-2 bg-[#14223D] hover:bg-[#172645] text-white font-sans text-bold tracking-normal leading-normal text-sm md:text-lg px-12 rounded-lg py-3 w-full"
            >
                {isUnderMaintenance ? 'Continue' : 'Close'}
            </button>
        </Modal>
    );
}

export default UnderMaintenance;
