

import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Modal } from '../modal/modal';
import { FormEvent, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { GENERATE_SETUP_INTENT } from '../../graphql/mutations/get-setup-intent';

// const STRIPE_API_KEY = 'pk_live_51PlUIr01QYTPK55qOcBrt4McSlLmWazJFlDStsbQoQA3vWaF3uja23qS68P0mecu1Yg4aVKxu34k1ATulhZ39QaN00LIQmgtnF';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);

interface Props {
  onClose?: () => void;
}

export function PaymentMethod({ onClose }: Props) {

  const [generateSetupIntent, { loading: loadingSetupIntent, data: setupIntentData, error: setupIntentError }] = useMutation(GENERATE_SETUP_INTENT);


  useEffect(() => {
    generateSetupIntent();
  }, [])

  return (
    <>
      {loadingSetupIntent && <p>Loading...</p>}
      {!loadingSetupIntent && setupIntentError && <p className="error-message">{setupIntentError.message}</p>}
      {!loadingSetupIntent && setupIntentData && (
        <Elements stripe={stripePromise} options={{
          clientSecret: setupIntentData.generateSetupIntent.client_secret,
        }}>
          <PaymentMethodElement onClose={onClose} />
        </Elements>
      )}
    </>
  );
}

interface ElementProps {
  onClose?: () => void;
}

function PaymentMethodElement({ onClose }: ElementProps) {

  const stripe = useStripe();
  const elements = useElements();

  const [paymentMethodError, setPaymentMethodError] = useState<string>();

  async function addPaymentMethod(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setPaymentMethodError(undefined);

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: 'http://localhost:3001',
      },
    });

    if (result.error) {
      setPaymentMethodError(result.error.message);
    } else {
      onClose && onClose();
    }
  }

  return (
    <Modal title="Add new card or bank" onClose={onClose}>
      <form onSubmit={addPaymentMethod}>
        {paymentMethodError && <p className="error-message">{paymentMethodError}</p>}
        <PaymentElement options={{
          business: { name: 'PropNerd' },
        }} />
        <br />
        <button className="!w-full py-6 btn btn-full">Submit</button>
      </form>
    </Modal>
  )
}