import { gql } from '@apollo/client'; 

export const QUERY_GET_PROPERTY_LIST = gql`query getProperties($take: Int, $skip: Int, $where: WhereInput) {
    properties(paginationOptions: { take: $take, skip: $skip }, where: $where) {
      id
      title
      excerpt
      description
      code
      price
      bed
      bath
      sqFootage
      transactionFee
      platformFee
      isFunded
      status
      order
      finalInvestorsCount
      manualFunded
      type{
        id
      }
      city {
        name
      }
      amenities {
        title
      }
      timelineEvents {
        title
        description
      }
      financials {
        projectedAnnualRoIPercentage
        projectedAnnualRoIValue
        year3ProjectedAnnualRoIPercentage
        year3ProjectedAnnualRoIValue
        annualRent
        effectiveDate
        endDate
      }
      rents {
        date
        rent
        isPaid
        isDisbursed
      }
      images {
        id
        ext
        order
      }
      pledges { 
        id
        status  
        amount
      }
      createdAt
    }
  
}`