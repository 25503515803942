import { gql } from "@apollo/client";

export const QUERY_GET_PROPERTY = gql`
  query getProperty($id: String!) {
    property(id: $id) {
      id
      title
      description
      code
      price
      transactionFee
      platformFee
      isRented
      bed
      bath
      sqFootage
      location
      isFunded
      minimumInvestment
      manualFunded
      finalInvestorsCount
      isFunded
      city {
        name
        excerpt
        description
        propertyDescription
      }
      images {
        id
        ext
        order
      }
      videos {
        id
        ext
        order
      }
      amenities {
        title
      }
      timelineEvents {
        title
        description
        date
      }
      financials {
        projectedAnnualRoIPercentage
        projectedAnnualRoIValue
        year3ProjectedAnnualRoIPercentage
        year3ProjectedAnnualRoIValue
        annualRent
        annualServiceCharges
        annualManagementAndMaintenanceCharges
        effectiveDate
        endDate
      }
      documents {
        id
        filename
        ext
      }
      pledges {
        id
        amount
        status
      }
      rents {
        date
        rent
        isPaid
        isDisbursed
      }
      createdAt
    }
  }
`;
