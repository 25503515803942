export function toCurrencyDisplay(value: number, thousandSeparator = true, currency: string = '£', subUnit: number = 100) {
  if (value == null || value === undefined || isNaN(value)) {
    return '';
  }
  if (thousandSeparator) {
    return `${currency}${(value / subUnit).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
  return `${currency}${(value / subUnit).toFixed(0)}`;
}
export function toHumanReadableCurrency(value: number, decimals: number = 2, currency: string = '£', subUnit: number = 100) {
  if (value === null || value === undefined || isNaN(value)) {
    return '';
  }

  value = value / subUnit;

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find(item => value >= item.value);
  // Handle sub-1 values explicitly
  if (value < 1 && value > 0) {
    return `${currency}${value.toFixed(decimals).replace(regexp, "")}`;
  }
  return item ? `${currency}${(value / item.value).toFixed(decimals).replace(regexp, "").concat(item.symbol)}` : `${currency}0`;
}

export function toHumanReadableCurrencyDisplay(value: number, currency: string = '£', subUnit: number = 100) {
  if (value == null || value === undefined || isNaN(value)) {
    return '';
  }

  value = value / subUnit;

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find(item => value >= item.value);
  return item ? `${currency}${(value / item.value).toFixed(2).replace(regexp, "").concat(item.symbol)}` : `${currency}0`;
}

export function removeCurrencySubunits(value?: number, subUnit: number = 100): number | null {
  if (value == null || value === undefined || isNaN(value)) {
    return null;
  }
  return parseInt((value / subUnit).toFixed(0));
}

export function addCurencySubunits(value?: number, subUnit: number = 100): number | null {
  if (value == null || isNaN(value)) {
    return null;
  }
  return Math.round(value * subUnit);
}

export function toTokens(value: number, thousandSeparator = true, subUnit: number = 100) {
  if (value == null || value === undefined || isNaN(value)) {
    return '';
  }
  if (thousandSeparator) {
    return `${(value / subUnit / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
  return (value / subUnit / 100).toFixed(0);
}

export function toTokensInt(value: number, subUnit: number = 100): number {
  if (value == null || isNaN(value)) {
    return 0;
  }

  return Math.round(value / subUnit / 100);
}