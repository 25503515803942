import { gql } from "@apollo/client";

export const GET_PAYMENT_INTENT_STATUS = gql`
  query GetPaymentIntentStatus($paymentIntentId: String!) {
    getPaymentIntentStatus(paymentIntentId: $paymentIntentId) {
      id
      status
      amount
      currency
    }
  }
`;