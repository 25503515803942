import { gql } from "@apollo/client"

export const QUERY_GET_PENDING_PLEDGES = gql`query getPendingPledges($skip: Int, $take: Int) {
  getPendingPledges(paginationOptions:{skip: $skip, take: $take}) {
    id
    amount
    property {
      id
      title
     
    }
  }
}`

export const QUERY_GET_PLEDGE_PROPERTY = gql`query getPledgeProperty($id:String!) {
  property(id:$id) {
    id
    title
    minimumInvestment
    financials {
      annualAppreciation
    }
    rents {
      rent
    }
    images {
        id
        ext
      }
  }
}`

export const QUERY_GET_CONFIRMED_PLEDGES = gql`query getConfirmedPledges {
  getConfirmedPledges {
    id
    amount
    status
    property {
      id
      title
      city {
        id
        name
      }
      rents {
        id
        rent
      }
    }
  }
}`

export const QUERY_GET_UPCOMING_RENTS = gql`query getUpcomingRents {
  getUpcomingRents {
    property {
      id
      title
    }
    sharePercentage
    dueDate
    totalRent
  }
}`
