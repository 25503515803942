import { gql } from "@apollo/client";

export const MUTATION_SEND_OTP = gql`
  mutation sendOTP($userId: String!, $deviceId: String!, $method: String!) {
    sendOTP(userId: $userId, deviceId: $deviceId, method: $method)
  }
`;

export const MUTATION_VERIFY_OTP = gql`
  mutation verifyOTP(
    $userId: String!
    $deviceId: String!
    $otp: String!
    $method: String!
  ) {
    verifyOTP(
      userId: $userId
      deviceId: $deviceId
      otp: $otp
      method: $method
    ) {
      user {
        id
        email
      }
      token
      lastLogin
    }
  }
`;

export const MUTATION_VERIFY_OTP_PHONE = gql`
  mutation verifyOTPToPhone($phone: String!, $otp: String!) {
    verifyOTPToPhone(phone: $phone, otp: $otp) {
      success
    }
  }
`;

export const MUTATION_RESEND_OTP_PHONE = gql`
  mutation resendOTPToPhone($phone: String!) {
    resendOTPToPhone(phone: $phone) {
      success
    }
  }
`;

export const MUTATION_UPDATE_USER = gql`
  mutation updateUser($firstName: String, $lastName: String, $phone: String) {
    updateUser(
      data: { firstName: $firstName, lastName: $lastName, phone: $phone }
    ) {
      success
      message
    }
  }
`;

export const MUTATION_CHANGE_PASSWORD = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;
