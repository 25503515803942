import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

interface TableSkeletonProps {
  width: number;
  height: number;
}

export default function TableSkeleton({ width, height }: TableSkeletonProps) {
  return (
    <Box sx={{ width: width, marginLeft:'50%' }}>
      <Skeleton animation="wave" width={width} height={height / 4} />
      <Skeleton animation="wave" width={width} height={height / 4} />
      <Skeleton animation="wave" width={width} height={height / 4} />
      <Skeleton animation="wave" width={width} height={height / 4} />
    </Box>
  );
}
