import { gql } from "@apollo/client";

export const QUERY_DASHBOARD_STATS = gql`
  query dashboardStats($startDate: String!, $endDate: String!) {
    getDashboardStats(data: { startDate: $startDate, endDate: $endDate }) {
      deposits {
        amount
        date
      }
      pledges {
        amount
        date
      }
      investments {
        property {
          id
          title
        }
        percentage
      }
      annualReturns {
        property {
          id
          title
        }
        annualReturn
      }
      annualAppreciations {
        property {
          id
          title
        }
        annualAppreciation
      }
    }
  }
`;
