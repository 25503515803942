export const checkServerStatus = async (): Promise<boolean> => {
  try {
    const response = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT!, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          query {
            __typename
          }
        `,
      }),
    });
    return response.ok;
  } catch (error) {
    return false;
  }
};
