import { gql } from "@apollo/client";

export const MUTATION_REGISTER = gql`
  mutation registerUser(
    $email: String!
    $phone: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $country: String!
    $investmentDuration: Float
    $investmentApproach: String
    $deviceId: String!
    $osVersion: String!
    $deviceType: String!
    $model: String!
  ) {
    register(
      data: {
        email: $email
        phone: $phone
        password: $password
        firstName: $firstName
        lastName: $lastName
        country: $country
        investmentDuration: $investmentDuration
        investmentApproach: $investmentApproach
      }
      deviceData: {
        deviceId: $deviceId
        osVersion: $osVersion
        deviceType: $deviceType
        model: $model
      }
    ) {
      id
      firstName
      lastName
      email
      accessToken
    }
  }
`;
