import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { AuthService } from '../services/auth.service';

export function useAuthAndErrorHandling(error?: ApolloError) {
  const navigate = useNavigate();

  useEffect(() => {
    if (error && ((error.message.includes('Invalid token')) || (error.message.includes('Authorization header is missing')) || error.message.includes('403') ) ) {
      AuthService.logout(navigate);
      window.location.reload(); // Reload the page after logging out
    }
  }, [error, navigate]);

}
