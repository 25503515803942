import { ReactComponent as YellowLogo } from '../../assets/svgs/yellow-logo-without-text.svg';
import { ReactComponent as BlueLogo } from '../../assets/svgs/blue-logo-without-text.svg';
import '../../assets/styles/intercom.css';
import { useState } from 'react';
import { Intercom, show, hide } from '@intercom/messenger-js-sdk';

interface Props {
  type?: 'yellow' | 'blue';
}

export function IntercomButton({ type = 'yellow' }: Props) {
  const Logo = type === 'blue' ? BlueLogo : YellowLogo;

  // State to track if Intercom is open or closed
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleIntercom = () => {
    if (isOpen) {
      show();
    } else {
      hide();
    }
    setIsOpen(!isOpen);
  };

  
  return (
    <div
      className={`intercom-button-container ${type}`}
      onClick={show}
    >
      <Logo width="24" style={{ display: 'block' }} />
    </div>
  );
}
