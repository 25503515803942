// ProfileSkeleton.tsx
import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export default function ProfileSkeleton() {
  return (
    <Box sx={{ maxwidth: 600 }}>
        <Skeleton variant="rectangular" animation="wave" width="90%" height={60} sx={{ marginBottom: 4 }} />
        <Skeleton variant="rectangular" animation="wave" width="90%" height={60} sx={{ marginBottom: 4 }} />
        <Skeleton variant="rectangular" animation="wave" width="90%" height={60} sx={{ marginBottom: 4 }} />
        <Skeleton variant="rectangular" animation="wave" width="90%" height={60} sx={{ marginBottom: 4 }} />
        <Skeleton variant="rectangular" animation="wave" width='10%' height={50} sx={{ marginBottom: 2 }} />
    </Box>
  );
}
