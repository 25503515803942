import { createContext, useState, ReactNode, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_NOTIFICATION_STATUS } from "../graphql/queries/get-notification-status";
import { AuthService } from "../services/auth.service";
import { set } from "date-fns";

interface NotificationContextProps {
  turnNotificationsOn: boolean;
  hideNotificationBar: boolean;
  loading: boolean;
  setTurnNotificationsOn: (value: boolean) => void;
  setHideNotificationBar: (value: boolean) => void;
}

export const NotificationContext = createContext<NotificationContextProps>({
  turnNotificationsOn: false,
  hideNotificationBar: false,
  loading: true,
  setTurnNotificationsOn: () => {},
  setHideNotificationBar: () => {},
});

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [turnNotificationsOn, setTurnNotificationsOn] = useState<boolean>(false);
  const [hideNotificationBar, setHideNotificationBar] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [deviceId, setDeviceId] = useState<string | null>(null);

  useEffect(() => {
    const token = AuthService.getToken();
    if (token) {
      setIsAuthenticated(true);
    } else {
      setLoading(false); // If not authenticated, loading is done
    }

    setDeviceId(localStorage.getItem("deviceId"));

  }, []);

  const { data, loading: queryLoading } = useQuery(GET_NOTIFICATION_STATUS, {
    variables: { deviceId },
    skip: !isAuthenticated, // Skip query execution if not authenticated
  });

  useEffect(() => {
    if (data && data.getNotificationStatus) {
      console.log("Notification status:", data.getNotificationStatus);
      setTurnNotificationsOn(data.getNotificationStatus.turnNotificationsOn);
      setHideNotificationBar(data.getNotificationStatus.hideNotificationBar);
    }
    setLoading(false); // Loading is done after the query
  }, [data]);

  return (
    <NotificationContext.Provider
      value={{
        turnNotificationsOn,
        hideNotificationBar,
        loading: loading || queryLoading, // Combine loading states
        setTurnNotificationsOn,
        setHideNotificationBar,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
