import React, { useState } from 'react'
import { ReactComponent as EyeIcon } from '../../assets/svgs/eye.svg';
import { ReactComponent as EyeSlashIcon } from '../../assets/svgs/eye-slash.svg';

interface PasswordFieldsProps {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    className?: string;
}

const PasswordField = ({ value, onChange, placeholder, className }: PasswordFieldsProps) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <div className="relative w-full">
            <input
                className={`box-border w-full p-4 pr-10 text-base border border-gray-300 rounded ${className}`}
                type={showPassword ? "text" : "password"}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
            <button
                type="button"
                className="absolute right-4 top-4"
                onClick={() => setShowPassword(!showPassword)}
            >
                {showPassword ? <EyeIcon className="w-6 h-6" /> : <EyeSlashIcon className="w-6 h-6" />}
            </button>
        </div>
    )
}

export default PasswordField