import { gql } from "@apollo/client";

export const QUERY_GET_SYS_CONFIG = gql`
  query getAllConfigs {
    getAllConfigs {
      key
      value
      dataType
    }
  }
`;
