import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent } from '@mui/material';
import ConfettiCannon from '../../assets/img/congrats.png'; // Ensure this path is correct
import Confetti from 'confetti-react';

interface CongratulationsPopupProps {
  open: boolean;
  onClose: () => void;
  properties?: string[];
}

const CongratulationsPopup: React.FC<CongratulationsPopupProps> = ({ open, onClose, properties }) => {
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (open) {
      setShowConfetti(true);
      // Automatically stop the confetti after a few seconds
      setTimeout(() => setShowConfetti(false), 5000); // Adjust the time as needed
    }
  }, [open]);

  return (
    <>
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9999, pointerEvents: 'none' }}>
          <Confetti
            numberOfPieces={150}
            recycle={false}
            gravity={0.3}
            initialVelocityY={15}
            width={window.innerWidth}
            height={window.innerHeight}
            confettiSource={{ x: window.innerWidth / 2, y: 230, w: 10, h: 0 }}
            colors={['#FF00FF', '#00FFFF', '#FFFF00', '#FF0000', '#00FF00']}
          />
        </div>
      <Dialog className='rounded-lg' open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogContent className="mx-6 rounded-2xl text-center my-8">
          <img src={ConfettiCannon} className="max-w-52 mx-auto mb-4 mt-2" alt="Confetti Cannon" />
          <h1 data-test="congratulations" className="font-sans text-deep-blue font-bold leading-normal text-4xl tracking-wide my-4">Congratulations!</h1>
          <p className="text-lg mb-6 text-center px-10">
            You've just taken a significant step towards securing your financial future by investing in{' '}
            <span className="font-bold">{properties?.join(', ')}</span>🏡.
          </p>
          <button 
            data-test="close-btn"
            className="mt-10 rounded-lg bg-[#14223d] w-full hover:bg-[#0f1c33] text-white font-sans tracking-normal leading-normal text-lg px-12  mb-4 py-4"
            onClick={onClose}
          >
            Close
          </button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CongratulationsPopup;
