import { gql } from "@apollo/client";

export const GET_TRANSACTIONS = gql`query getTransactions($take:Int, $skip:Int) {
  getTransactions(take:$take, skip:$skip) {
    type
    amount
    code
    createdAt
    property {
      title
    }
    status
  }
}`