import React, { createContext, useContext, useEffect, useState, PropsWithChildren, useRef } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { checkServerStatus } from '../utils/server.util';

const GET_SETTINGS = gql`
  query GetSettings($appVersion: String!) {
    settings(appVersion: $appVersion) {
      appVersion
      settings
    }
  }
`;

interface Settings {
  blurProperties?: boolean;
}

interface SettingsContextValue {
  settings: Settings | null;
  loading: boolean;
  error: any;
}

const defaultValue: SettingsContextValue = {
  settings: null,
  loading: true,
  error: null,
};

const SettingsContext = createContext<SettingsContextValue>(defaultValue);

interface SettingsProviderProps {
  appVersion: string;
}

export const SettingsProvider: React.FC<PropsWithChildren<SettingsProviderProps>> = ({ children, appVersion }) => {
  const [settings, setSettings] = useState<Settings | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);

  const [serverLive, setServerLive] = useState<boolean>(false);


  

  const { data, loading, error } = useQuery(GET_SETTINGS, {
    errorPolicy: 'all',
    skip: !serverLive, // Use the skip option based on server status
    variables: { appVersion }, 
  });

  useEffect(() => {
    const checkServer = async () => {
      const isLive = await checkServerStatus();
      setServerLive(isLive);
    };
    checkServer();
  }, []);


  useEffect(() => {
    if (data) {
      const settings = data?.settings?.settings || null;
      setSettings(settings);
    }
  }, [data]);

  return (
    <SettingsContext.Provider  value={{ settings, loading, error }}  >
      {children} 
    </SettingsContext.Provider>
    
  );
};

export const useSettings = () => useContext(SettingsContext);
