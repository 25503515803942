import { gql } from "@apollo/client";

export const GET_BALANCE = gql`
  query getBalanceObject {
    getBalanceObject {
      getBalance
      totalDepositSum
    }
  }
`
