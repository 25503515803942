// WelcomePopup.tsx
import React from 'react';
import { Button, Dialog, DialogContent } from '@mui/material';
import { ReactComponent as SvgLogo } from '../../assets/img/common/logo.svg'; // Ensure this path is correct

interface WelcomePopupProps {
  open: boolean;
  onClose: () => void;
}

const WelcomePopup: React.FC<WelcomePopupProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent className="bg-gray-100 mx-6 rounded-2xl text-center my-8">
        <SvgLogo className="max-w-52 mx-auto mb-4 mt-2" />
        <h1 className="font-sans text-deep-blue font-bold leading-normal text-4xl tracking-wide my-4">Welcome to PropNerd</h1>
        <p className="italic mb-2">Unlock the Future of Real Estate Investments</p>
        <p className="text-lg mb-6 text-center px-10">Explore exclusive real estate opportunities, track your portfolio's performance, and invest with confidence. Let's get started on your investment journey.</p>
        <button 
          className="mt-10 bg-[#14223d] hover:bg-[#0f1c33] text-white font-sans tracking-normal leading-normal text-lg px-12 rounded-md mb-4 py-4"
          onClick={onClose}
        >
          Go to Marketplace
        </button>
      </DialogContent>
    </Dialog>
  );
};

export default WelcomePopup;
