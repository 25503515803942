import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

interface SkeletonColorProps {
  width: number | string;
  height: number | string;
}

const SkeletonColor: React.FC<SkeletonColorProps> = ({ width, height }) => {
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Skeleton
        animation="wave"
        variant="rounded"
        width={width}
        height={height}
      />
    </Box>
  );
};

export default SkeletonColor;
