import Cms_values from "../assets/cms-data.json";

export function t(key: string) {
  if (!key) {
    return key;
  }
  const [pageKey, sectionKey, valueKey] = key.split(".");

  if (!pageKey || !sectionKey || !valueKey) {
    return key;
  }

  const valueObj: any = Cms_values.values.find(
    (v: any) =>
      v.page === pageKey && v.section === sectionKey && v.key === valueKey
  );
  return valueObj ? valueObj.value : key;
}
