
import { gql } from "@apollo/client";

export const MUTATION_ADD_PROPERTY_BOOKMARK = gql`
  mutation addPropertyBookmark($propertyId: String!) {
    addPropertyBookmark(propertyId: $propertyId)
  }
`;

export const MUTATION_REMOVE_PROPERTY_BOOKMARK = gql`
  mutation removePropertyBookmark($propertyId: String!) {
    removePropertyBookmark(propertyId: $propertyId)
  }
`;
