import { gql } from "@apollo/client";

export const MUTATION_ADD_PASSWORD_RESET = gql`
  mutation addPasswordReset($email: String!) {
    addPasswordReset(data: { email: $email }) {
      id
    }
  }
`;

export const MUTATION_RESET_PASSWORD = gql`
  mutation resetPassword($id: String!, $code: String!, $newPassword: String!) {
    resetPassword(data: {id: $id, code: $code, newPassword: $newPassword}) {
      success
      message
    }
  }
`;
