import React, { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import './range.slider.css';

interface RangeSliderProps {
    title: string;
    startTitle: string;
    midTitle?: string;
    endTitle: string;
    isOverall: boolean;
    onValueChange: (value: string | number) => void;
}

const RangeSlider = ({ title, startTitle, midTitle, endTitle, isOverall, onValueChange }: RangeSliderProps) => {
    const values = isOverall ? ['Conservative', 'Medium', 'Aggressive'] : [2, 3, 5, 7];
    const [index, setIndex] = useState(0);

    useEffect(() => {
        onValueChange(values[index]);
    }, [index, values]);

    const handleValueChange = (event: any, newValue: number | number[]) => {
        setIndex(newValue as number);
    };

    const marks = values.map((value, idx) => ({
        value: idx,
        label: value,
    }));

    return (
        <div className="range-slider">
            <h2 style={{ padding: 0 }}>{title}</h2>
            <Slider
                value={index}
                min={0}
                max={values.length - 1}
                step={1}
                marks
                onChange={handleValueChange}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => values[value]}
                sx={{
                    '& .MuiSlider-thumb': {
                        color: "#F5CA48",
                        backgroundColor: "#F5CA48",
                        boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
                        width: '21px',
                        height: '21px',
                    },
                    '& .MuiSlider-track': {
                        color: "#f5ca48",
                    },
                    '& .MuiSlider-rail': {
                        color: "#2C3850"
                    },
                    '& .MuiSlider-mark': {
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#2C3850',
                        borderRadius: '50%',
                        marginLeft: '-10px',
                        color: "#2C3850",
                        opacity: 1,
                    },
                    '& .MuiSlider-mark.MuiSlider-markActive': {
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#F5CA48',
                        borderRadius: '50%',
                        marginLeft: '-10px',
                        color: "#F5CA48",
                        opacity: 1,
                    },
                }}
            />
            <div className="range-labels">
                <span style={{ textAlign: 'left' }}>
                    {startTitle}
                    {isOverall && <div>(Low risk, steady return)</div>}
                </span>
                {midTitle && <span>{midTitle}</span>}
                {!isOverall && values.slice(1, -1).map((val, idx) => (
                    <span key={idx}>
                        {val} Years
                    </span>
                ))}
                <span style={{ textAlign: 'right' }}>
                    {endTitle}
                    {isOverall && <div>(High risk, large return)</div>}
                </span>
            </div>
        </div>
    );
};

export default RangeSlider;
