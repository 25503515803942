import { useContext, useEffect } from "react";
import { ApolloContext } from "../apollo/apollo.component";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../services/auth.service";

export function GlobalErrorHandler() {
  const { apiError, tokenInvalidated } = useContext(ApolloContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (tokenInvalidated) {
      AuthService.logout(navigate);
      window.location.reload();
    }
  }, [tokenInvalidated]);

  return (
    apiError ? <div className="global-error">
      {apiError}
    </div> : null
  );
}