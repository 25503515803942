// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, isSupported } from 'firebase/messaging';
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDo0WcqtWpiLMFO_rXggHTWa4OM-Y04i0U",
  authDomain: "sincere-canyon-309808.firebaseapp.com",
  projectId: "sincere-canyon-309808",
  storageBucket: "sincere-canyon-309808.appspot.com",
  messagingSenderId: "271336062483",
  appId: "1:271336062483:web:2f6d69f6e1ca282efac9e6",
  measurementId: "G-DT33H092XT"
};
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export { getMessaging, firebaseApp, isSupported, analytics }