import { gql } from '@apollo/client';

export const QUERY_IS_PROPERTY_BOOKMARKED = gql`
  query IsPropertyBookmarked($propertyId: String!) {
    isPropertyBookmarked(propertyId: $propertyId)
  }
`;

export const QUERY_GET_BOOKMARKED_PROPERTIES = gql`query GetBookmarkedProperties {
  bookmarkedProperties {
    id
    title
    excerpt
    description
    code
    price
    bed
    bath
    sqFootage
    transactionFee
    platformFee
    isFunded
    status
    manualFunded
    type{
      id
    }
    city {
      name
    }
    amenities {
      title
    }
    timelineEvents {
      title
      description
    }
    financials {
      projectedAnnualRoIPercentage
      projectedAnnualRoIValue
      year3ProjectedAnnualRoIPercentage
      year3ProjectedAnnualRoIValue
      annualRent
      effectiveDate
      endDate
    }
    rents {
      date
      rent
      isPaid
      isDisbursed
    }
    images {
      id
      ext
      order
    }
    pledges { 
      id
      status  
      amount
    }
    createdAt
  }
}
`