export enum SysConfigDataType {
  STRING = "string",
  BOOLEAN = "boolean",
  NUMBER = "number",
}

export interface SysConfig {
  key: string;
  value: string;
  dataType: SysConfigDataType;
}

export function convertConfigValue(
  value: string,
  dataType: SysConfigDataType
): any {
  switch (dataType) {
    case SysConfigDataType.BOOLEAN:
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
      break;
    case SysConfigDataType.NUMBER:
      return Number(value);
    case SysConfigDataType.STRING:
    default:
      return value;
  }
}

export function processSysConfigs(configs: SysConfig[]): Record<any, any> {
  return configs.reduce<Record<any, any>>((acc, config) => {
    acc[config.key] = convertConfigValue(config.value, config.dataType);
    return acc;
  }, {});
}
