import { Link, useNavigate } from "react-router-dom";
import { AppRoute } from "../../routes";
import { AuthService } from "../../services/auth.service";

export function Error404Page() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }
  },[]);
  
  return (
    <>
        <section className="content account flex flex-col">
            <h1 className="font-sans text-deep-blue tracking-normal text-3xl mb-8 font-bold leading-normal ">404 Page Not Found</h1>
            <p>Looks like you've stumbled upon uncharted territory.</p>
            <p>No worries, even the most seasoned real estate investors get lost sometimes!</p>
            <p className="underline hover:text-gray-600"><Link to={AppRoute.Home}>Back to Home</Link></p>
        </section>
    </>
  );
}

function useEffect(arg0: () => void, arg1: never[]) {
  throw new Error("Function not implemented.");
}
