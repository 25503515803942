import React from "react";
import PdfIcon from "../../assets/svgs/pdf.svg"; // Replace with actual path to your PDF icon
import DownloadIcon from "../../assets/svgs/download.svg"; // Replace with actual path to your download icon

interface Document {
  id: string;
  filename: string;
  url: string;
}

interface DocumentsProps {
  documents: Document[] | null;
  propertyId: string;
}

const Documents: React.FC<DocumentsProps> = ({ documents, propertyId }) => {
  if (!documents || documents.length === 0) {
    return null;
  }

  return (
    <div className="mt-5">
      <h3 className="text-xl font-bold mb-5">Property Documents</h3>
      <ul className="flex flex-wrap gap-4 list-none p-0">
        {documents.map((document) => (
          <li
            key={document.id}
            data-test="document"
            className="relative bg-white p-4 rounded-xl shadow-md flex items-center w-[300px]"
          >
            <a
              href={document.url}
              rel="noreferrer"
              target="_blank"
              className="flex flex-col items-start no-underline text-inherit relative w-full justify-between"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-[#fef5f5] to-[#fddcdc] blur-[50px] rounded-xl z-[-1]" />
              <div className="flex justify-between items-center w-full">
                {/* <img src={PdfIcon} alt="PDF" className="w-10 h-10 mr-4" /> */}
                <p className="text-base font-semibold text-[#14223d] truncate">
                  {document.filename}
                </p>
                <img
                  src={DownloadIcon}
                  alt="Download"
                  className="w-6 h-6 ml-4"
                />
              </div>
              <div className="flex flex-col flex-grow"></div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Documents;
