import { useLazyQuery, useMutation } from '@apollo/client';
import { useState, useEffect, FormEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as SvgLogo } from '../../../assets/img/common/logo.svg';
import { GlobalErrorHandler } from '../../../components/error/global_error.component';
import { QUERY_EMAIL_EXISTS } from '../../../graphql/queries/get-email';
import { MUTATION_ADD_PASSWORD_RESET } from '../../../graphql/mutations/password-reset';
import UnderMaintenance from '../../../components/modal/under-maintenance';



// Define the shape of the data returned by the QUERY_EMAIL_EXISTS query
interface EmailExistsData {
  userExists: boolean;
}

// Define the shape of the variables for the GET_USER_BY_EMAIL query
interface UserByEmailVars {
  email: string;
}

// Define the shape of the variables for the MUTATION_ADD_PASSWORD_RESET mutation
interface AddPasswordResetVars {
  email: string;
}

export function AccountForgetPage() {
  const location = useLocation();
  const [infoMessage, setInfoMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isFormVisible, setIsFormVisible] = useState<boolean>(true);

  const [checkEmailExists, { loading: checkingEmail, error: checkEmailError }] = useLazyQuery<EmailExistsData, UserByEmailVars>(QUERY_EMAIL_EXISTS, {
    onCompleted: data => {
      if (data.userExists) {
        processPasswordReset(email);
      } else {
        setInfoMessage('');
        setErrorMessage('No account registered with this email address.');
        setIsFormVisible(true); // Show form again on error
      }
    },
    onError: error => {
      setErrorMessage(error.message);
      setInfoMessage('');
      setIsFormVisible(true); // Show form again on error
    }
  });


  const [addPasswordReset, { loading: addingReset, data: resetData }] = useMutation<void, AddPasswordResetVars>(MUTATION_ADD_PASSWORD_RESET, {
    onCompleted: () => {
      setInfoMessage("Password reset request processed. Please check your email for the reset link.");
      setErrorMessage('');
      setIsFormVisible(false); // Hide form on success
    },
    onError: (error) => {
      setErrorMessage(error.message);
      setInfoMessage('');
      setIsFormVisible(true); // Show form again on error
    }
  });


  const processPasswordReset = (email: string) => {
    addPasswordReset({
      variables: { email }
    });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (email) {
      setInfoMessage('Checking email registration...');
      setErrorMessage('');
      setIsFormVisible(false); // Hide form while processing
      checkEmailExists({ variables: { email } }); // Trigger the lazy query to check if the email exists
    }
  };

  const handleCloseUnderMaintenance = () => {
    setErrorMessage('');
  }

  return (
    <section className="flex flex-col content account">
    <GlobalErrorHandler />
    <UnderMaintenance
      errorType={errorMessage}
      onClose={handleCloseUnderMaintenance}
    />
    <SvgLogo className='mx-auto -mt-16 text-center max-w-52' />
    <h1 data-test="heading" className='mt-24 mb-4 font-sans text-5xl font-bold leading-normal tracking-wide text-deep-blue'>Reset your password</h1>
    <p className='max-w-screen-md mx-auto mb-10 -mt-2 font-sans text-lg font-normal leading-relaxed tracking-tight text-center text-deep-blue'>Enter your email to receive a verification code and password reset link.</p>
    {isFormVisible && (
      <form className='w-1/2 max-w-md p-8 mx-auto bg-white rounded-lg shadow-md card' onSubmit={onSubmit}>
        <div className="flex flex-col gap-2 p-4">
          <label className='mb-2 -mt-2 text-base font-bold tracking-normal'>Email</label>
          <input data-test="email" className='box-border w-full p-4 text-base border border-gray-300 rounded' autoFocus type="email" value={email} placeholder='Enter your email address' onChange={e => setEmail(e.target.value)} />
        </div>

        {infoMessage && <div data-test="info-message" className="px-12 py-6 mb-12 border rounded-lg bg-light-grey">{infoMessage}</div>}
        {errorMessage && errorMessage !== 'UNDER_MAINTENANCE' && <div data-test="error-message" className="px-12 py-6 mb-12 text-red-500 border rounded-lg bg-light-grey">{errorMessage}</div>}

        <div className="flex flex-col gap-2 p-4">
          <button data-test="reset-password" type="submit" className='h-12 btn btn-primary' disabled={checkingEmail || addingReset}>Reset Password</button>
        </div>
      </form>
    )}
    {!isFormVisible && (infoMessage || errorMessage) && (
      <div className='w-1/2 max-w-md p-8 mx-auto bg-white rounded-lg shadow-md card min-w-fit'>
        {infoMessage && <div data-test="info-message" className="px-12 py-6 mb-12 border rounded-lg bg-light-grey">{infoMessage}</div>}
        {errorMessage && errorMessage !== 'UNDER_MAINTENANCE' && <div data-test="error-message" className="px-12 py-6 mb-12 text-red-500 border rounded-lg bg-light-grey">{errorMessage}</div>}
      </div>
    )}
  </section>
  );
}
