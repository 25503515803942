import React from 'react';
import '../../assets/styles/progress-steps.css';

interface Step {
    step: number;
    label: string;
    title?: string;
    description?: string;
}

interface ProgressStepsProps {
    steps: Step[];
    currentStep: number; // Controlled step prop
    onStepChange: (step: number) => void; // Step change handler
    hideNavigation?: boolean;
    direction?: 'horizontal' | 'vertical';
}

const ProgressSteps: React.FC<ProgressStepsProps> = ({
    steps,
    currentStep,
    onStepChange,
    hideNavigation,
    direction = 'horizontal',
}) => {
    const totalSteps = steps.length;

    // Go to the next step if not on the last step
    const nextStep = () => {
        if (currentStep < totalSteps) {
            onStepChange(currentStep + 1);
        }
    };

    // Go to the previous step if not on the first step
    const prevStep = () => {
        if (currentStep > 1) {
            onStepChange(currentStep - 1);
        }
    };

    const calculateProgressWidth = () => {
        return ((currentStep - 1) / (totalSteps)) * 100 + '%';
    };
    const calculateBgLineWidth = () => {
        return ((totalSteps - 1) / (totalSteps)) * 100 + '%';
    };
    const calculateStepItemWidth = () => {
        return ((1) / (totalSteps)) * 100 + '%';
    };


    return (
        <div className={`container ${direction === 'horizontal' ? 'horizontal' : 'vertical'}`}>
            <div className={`relative ${direction === 'horizontal' ? 'horizontal move-right' : 'vertical'}`}>
                {/* Background Line */}
                <div
                    className={`line-background ${direction === 'horizontal' ? 'horizontal' : 'vertical'}`}
                    style={{ [direction === 'horizontal' ? 'width' : 'height']: calculateBgLineWidth() }}
                ></div>

                {/* Dynamic Progress Line */}
                <div
                    className={`progress-line ${direction === 'horizontal' ? 'horizontal' : 'vertical'}`}
                    style={{ [direction === 'horizontal' ? 'width' : 'height']: calculateProgressWidth() }}
                ></div>

                {/* Steps */}
                <div className="step-container">
                    {steps.map(({ step, label }) => (
                        <div key={step} className={`step-item ${currentStep >= step ? 'active' : ''}`}
                            style={{ [direction === 'horizontal' ? 'width' : 'height']: calculateStepItemWidth() }}
                        >
                            <div className={`step-circle ${currentStep >= step ? 'active' : ''}`}>
                                <span>{step}</span>
                            </div>
                            <span className={`step-label ${currentStep >= step ? 'active' : ''}`}>{label}</span>
                        </div>
                    ))}
                </div>
            </div>

            {/* Divider */}
            <div className="divider"></div>

            {/* Render content for the current step */}
            <div className="content-container">
                <p className="content-title">{steps[currentStep - 1]?.title}</p>
                <p className="content-description">{steps[currentStep - 1]?.description}</p>
            </div>

            {/* Navigation Buttons */}
            {!hideNavigation && (
                <div className="navigation-buttons">
                    <button
                        onClick={prevStep}
                        disabled={currentStep === 1}
                        className={`button ${currentStep === 1 ? 'disabled' : 'primary'}`}
                    >
                        Previous
                    </button>
                    <button
                        onClick={nextStep}
                        disabled={currentStep === totalSteps}
                        className={`button ${currentStep === totalSteps ? 'disabled' : 'primary'}`}
                    >
                        Next
                    </button>
                </div>
            )}
        </div>
    );
};

export default ProgressSteps;
