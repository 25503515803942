export function toPercentageDisplay(value: number): string {
  if (value == null || value === undefined || isNaN(value)) {
    return '';
  }
  return `${(value / 100).toFixed(2)}%`;
}

export function htmlParagraphs(text: string): string {
  if (!text) {
      return '';
  }

  return text
      .split(/\n\s*\n/) // Split by two or more newlines, possibly with spaces
      .filter(paragraph => paragraph.trim().length > 0) // Remove empty paragraphs
      .map(paragraph => `<p>${paragraph.trim()}</p>`) // Wrap each paragraph in <p> tags
      .join('\n'); // Join the paragraphs with newline characters for readability
}