import { gql } from "@apollo/client";

export const SAVE_FCM_TOKEN = gql`
  mutation SaveFcmToken($fcmToken: String!, $turnNotificationsOn: Boolean!, $deviceId: String!) {
    saveFcmToken(fcmToken: $fcmToken, turnNotificationsOn: $turnNotificationsOn, deviceId: $deviceId) {
      success
      message
    }
  }
`;

export const UPDATE_HIDE_NOTIFICATION_BAR = gql`
  mutation UpdateHideNotificationBar($hideNotificationBar: Boolean!, $deviceId: String!) {
    updateHideNotificationBar(hideNotificationBar: $hideNotificationBar , deviceId: $deviceId) {
      success
      message
    }
  }
`;