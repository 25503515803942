import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

export default function CardSkeleton() {
  return (
    <Box className='item bg-white rounded-lg w-20 shadow-md'>
      <div className='highlight bg-deep-blue flex flex-row items-center justify-center gap-2 -top-left rounded-t-lg -top-right'>
        <Skeleton animation="wave" variant="text" width={80} height={20} />
      </div>
      <div className='image-slides'>
        <Skeleton animation="wave" variant="rectangular" width="100%" height={150} />
      </div>
      <div className='gallery-content flex flex-col p-4'>
        <div className='tags flex gap-2'>
          <Skeleton animation="wave" variant="text" width={60} height={20} />
        </div>
        <h3 className='title text-lg font-semibold font-sans text-deep-blue tracking-normal leading-normal'>
          <Skeleton animation="wave" variant="text" width="80%" />
        </h3>
        <div className='pricing flex justify-between items-baseline'>
          <h4 className='text-lg text-yellow-500 font-semibold'>
            <Skeleton animation="wave" variant="text" width={60} />
          </h4>
          <h5 className='text-sm font-light'>
            <Skeleton animation="wave" variant="text" width={40} />
          </h5>
        </div>
        <div className='progress'>
          <Skeleton animation="wave" variant="rectangular" width="100%" height={10} />
        </div>
        <table className='financials bg-gray-100 rounded-md'>
          <tbody>
            {Array.from({ length: 4 }).map((_, index) => (
              <tr key={index}>
                <td className='py-1 pl-2'>
                  <Skeleton animation="wave" variant="text" width={100} />
                </td>
                <td className='pr-1 font-semibold text-right text-base'>
                  <Skeleton animation="wave" variant="text" width={40} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
}
