
interface ConfirmationModalProps {
    id: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (id: string) => void;
    loading?: boolean;
}

export const ConfirmationModal = ({ id, isOpen, onClose, onConfirm, loading }: ConfirmationModalProps) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="p-6 bg-white rounded-lg shadow-lg">
                <h2 data-test="confirm-deletion" className="mb-4 text-lg font-semibold">Confirm Deletion</h2>
                <p>Are you sure you want to delete this payment method?</p>
                <div className="flex justify-end mt-4">
                    <button
                        data-test="cancel-btn"
                        className="p-2 mr-2 bg-gray-300 rounded hover:bg-gray-400"
                        disabled={loading}
                        onClick={onClose}>Cancel</button>
                    <button
                        className="p-2 text-white bg-red-500 rounded hover:bg-red-600"
                        data-test="delete-btn"
                        disabled={loading}
                        onClick={() => {
                            onConfirm(id);
                            onClose();
                        }}>
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};
