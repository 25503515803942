import React from "react";
import Zoom from "react-medium-image-zoom";
import { getPropertyImageUrl } from "../../utils/image.utils"; // Adjust the import path as necessary
import { getPropertyVideoUrl } from "../../utils/video.utils";

interface SmallImagesProps {
  media: { id: string; ext: string; order: number; type: "image" | "video" }[]
  propertyId: string;
  onMediaSelect: (media: any) => void;
  selectedMedia: any;
  containerRef: React.RefObject<HTMLDivElement>;
}

const SmallImages: React.FC<SmallImagesProps> = ({
  media,
  propertyId,
  onMediaSelect,
  selectedMedia,
  containerRef,
}) => {
  const renderMedia = (mediaItem: any, index: number) => {
    const isVideo = mediaItem.type === "video";


    if (isVideo) {
      return (
        <video
          data-test="small-image"
          className={`flex items-center justify-center object-cover h-32 small-image bg-gray-300 rounded-lg cursor-pointer w-96  ${selectedMedia && selectedMedia.id === mediaItem.id
            ? "border-4 border-[#14223d]"
            : ""
            }`}
          onClick={() => onMediaSelect(index)}
          key={mediaItem.id}
        >
          <source
            src={getPropertyVideoUrl(propertyId, mediaItem)}
            type={`video/${mediaItem.ext}`}
          />
          Your browser does not support the video tag.
        </video>
      );
    }

    // For images, render them normally
    return (
      <img
        data-test="small-image"
        key={mediaItem.id}
        className={`w-96 h-32 object-cover rounded-lg cursor-pointer small-image ${selectedMedia && selectedMedia.id === mediaItem.id
          ? "border-4 border-[#14223d]"
          : ""
          }`}
        src={getPropertyImageUrl(propertyId, mediaItem)}
        alt="Property"
        onClick={() => onMediaSelect(index)}
      />
    );
  };

  return (
    <div
      className="hidden w-full gap-4 overflow-x-auto overflow-y-hidden lg:flex scroll-smooth"
      ref={containerRef}
    >
      {media.map((mediaItem, index) => {
        if (!mediaItem || !mediaItem.id) return null;

        return renderMedia(mediaItem, index); // Render media (image or video)
      })}
    </div>
  );
};

export default SmallImages;
