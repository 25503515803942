import { PropsWithChildren, useEffect } from "react";

interface Props extends PropsWithChildren {
  title?: string;
  onClose?: () => void;
  className?: string;
}

export function Modal({ title, onClose, className, children }: Props) {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose && onClose();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-50 "
        onClick={() => onClose && onClose()}
      ></div>
      <div className={`modal  max-h-[90vh] overflow-y-scroll z-50 scroll-hidden ${className || ""}`}>
        {title &&
          <div className={`flex justify-between pb-2 border-b mb-4 ${className || ""}`}>
            <div className="font-semibold">{title}</div>
            <div
              className="py-1 px-2 cursor-pointer rounded-md hover:border"
              onClick={() => onClose && onClose()}
            >
              x
            </div>
          </div>
        }
        <div className="modal-content">{children}</div>
      </div>
    </>
  );
}
