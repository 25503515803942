import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

interface BoxSkeletonProps {
  width: number;
  height: number;
}

export default function BoxSkeleton({ width, height }: BoxSkeletonProps) {
  return (
    <Box sx={{ width: width }}>
      <Skeleton animation="wave" width={width} height={height} />
    </Box>
  );
}
