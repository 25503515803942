import { gql } from "@apollo/client";

export const QUERY_IS_PERSONAL_INFO_VALID = gql`
  query IsPersonalInfoValid(
    $email: String!
    $phone: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $country: String!
  ) {
    validatePersonalInfo(
      data: {
        email: $email
        phone: $phone
        password: $password
        firstName: $firstName
        lastName: $lastName
        country: $country
      }
    ) {
       success
    }
  }
`;
