import { GlobalErrorHandler } from "../../components/error/global_error.component";
import { Sidebar } from "../../components/sidebar/sidebar";
import { KycCheck } from "../account/kyc/kyc";

// Function to trigger the Intercom popup
function showIntercom() {
  if (typeof window !== 'undefined' && window.Intercom) {
    window.Intercom('show');
  }
}

export function HelpPage() {
  return (
    <>
      <KycCheck />
      <GlobalErrorHandler />

      <Sidebar />

      <section className="content portfolio">
        <h1 className="font-sans text-deep-blue tracking-normal text-3xl mb-8 font-bold leading-normal">Help &amp; Support</h1>
        <div className="card flex flex-col justify-center" style={{ maxWidth: 600 }}>
          <div className="mb-4">
            <p className="font-semibold  mr-1 ">Need help?</p>
            <p>We're here to assist you every step of the way. Whether you have questions, need assistance, or want to share feedback, our dedicated support team is just a message or call away.</p>
            <p className="mt-6  font-semibold ">E-mail: <a href="mailto:info@propnerd.io">info@propnerd.io</a></p>
            <p className="mb-6  font-semibold ">Phone: <a href="tel:+442033073187">+44(0)203 307 3187</a></p>
            <p>Our support representatives are available to answer your inquiries and provide personalized assistance. Reach out to us today and experience exceptional support tailored to your needs!</p>
          </div>
          <div className="btn py-6" onClick={showIntercom}>Start a Chat</div>

        </div>
      </section>
    </>
  );
}
