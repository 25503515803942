import { gql } from "@apollo/client";

export const QUERY_GET_CURRENCIES = gql`
  query getCurrencies {
    currencies {
      id
      name
      type
    }
  }
`;
