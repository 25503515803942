import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const PropertySkeleton = () => {
  return (
    <Box className="content property">
      {/* Header Skeleton */}
      <div className="">
        <Skeleton variant="text" width="100%" height={160} />
      </div>

      {/* Image and Overview Section */}
      <div className="section-container flex flex-wrap gap-4 mt-4">
        <Skeleton
          variant="rectangular"
          height={480}
          width="70%"
          className="mb-3 big-image-skeleton"
          style={{ borderRadius: '10px' }}
        />
        <div
          className="detail-cards-skeleton flex flex-col gap-2"
          style={{ width: '25%' }}
        >
          <Skeleton variant="rectangular" height={235} style={{ borderRadius: '10px' }} />
          <Skeleton variant="rectangular" height={235} style={{ borderRadius: '10px' }} />
        </div>
      </div>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={250}
        className="mb-4 hidden-mobile-skeleton"
        style={{ borderRadius: '10px' }}
      />

      {/* Tab Section Skeleton */}
      <div className="mt-5">
        <Skeleton variant="text" width="30%" height={50} className="mb-3" style={{ borderRadius: '10px' }} />
        <Skeleton variant="rectangular" width="100%" height={300} className="mb-4" style={{ borderRadius: '10px' }} />
      </div>

      {/* Map Section Skeleton */}
      <h2 className="documents-title mt-5">
        <Skeleton variant="text" width="30%" height={40} style={{ borderRadius: '10px' }} />
      </h2>
      <div className="map">
        <Skeleton variant="rectangular" width="100%" height={300} style={{ borderRadius: '10px' }} />
      </div>
    </Box>
  );
};

export default PropertySkeleton;
